import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';
import FormContainer from '@/components/FormContainer';

import Header from './components/Header'
import Menu from './components/Menu'

import * as S from './styles'

const Default = ({ children, form }) => {
  const { personalName, establishment, currentEstablishment, isLoggedIn, handleLogout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleRouteChange = useCallback(() => {
    if (!isLoggedIn && !location.pathname.includes('/login')) {
      const isAutomaticLoggout = true;
      handleLogout(isAutomaticLoggout);
      navigate('/dashboard/login');
    }
  }, [isLoggedIn, handleLogout, navigate, location]);

  useEffect(() => {
    handleRouteChange()
  }, []);

  if(!isLoggedIn && !location.pathname.includes('/login')) {
    return <></>
  }

  const Render = ({ children}) => {
    if (form) {
      return (
        <FormContainer>
          {children}
        </FormContainer>
      )
    }

    return children
  }

  return (
    <S.Container>
      <Menu establishment={establishment} currentEstablishment={currentEstablishment} />

      <S.Wrapper>
        <Header personalName={personalName} />

        <S.Content>
          <Render>
            {children}
          </Render>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  )
}

export default Default
