import { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import { logIn, logOut } from "@/services/auth";
import { AuthContext } from "@/providers/AuthProvider";
import { ROLE } from "@/config/roles";

const paths = {
  customer: "paciente",
  dashboard: "dashboard",
};

const useAuth = () => {
  const {
    personalName,
    establishment,
    currentEstablishment,
    isLoggedIn,
    setUserInfo,
    logout,
  } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const currentLocation = () => location.pathname;
  const pathLocation = () =>
    currentLocation().includes("/dashboard") ? "dashboard" : "customer";

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/${paths[pathLocation()]}/login`);
    }
  }, [isLoggedIn]);

  const handleLogin = async (identifier, password, type = ROLE.DOCTOR) => {
    const user = await logIn({ identifier, password });
    setUserInfo({
      ...user,
    });
  };

  const handleLogout = (isAutomaticLoggout = false) => {
    const actions = () => {
      logOut();
      logout();
      navigate(`/${paths[pathLocation()]}/login`);
    };

    if (isAutomaticLoggout) {
      Swal.fire({
        icon: "info",
        title: "Sua sessão expirou",
        text: "Estamos te redirecionando para a página de login",
        timer: 3000,
        timerProgressBar: true,
      });

      return actions();
    }

    Swal.fire({
      icon: "warning",
      title: "Deseja realmente sair?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        actions();
      }
    });
  };

  return {
    personalName,
    establishment,
    currentEstablishment,
    isLoggedIn,
    handleLogin,
    handleLogout,
  };
};

export default useAuth;
