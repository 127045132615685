import 'react-datepicker/dist/react-datepicker.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-tooltip/dist/react-tooltip.css'

import React from 'react';
import ReactDOM from 'react-dom/client';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/globalStyles';
import theme from './styles/theme';
import App from './app';
import LoaderProvider from './providers/LoaderProvider';
import AuthProvider from './providers/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <LoaderProvider>
        <GlobalStyle />
        <App />
      </LoaderProvider>
    </AuthProvider>
  </ThemeProvider>
  // </React.StrictMode>
);