import styled, { css } from "styled-components";

export const Container = styled.span.attrs(({ variant }) => ({ variant: variant || "primary" }))`
  ${({ theme, variant }) => css`
    display: flex;
    align-items: center;

    color: ${theme.colors[variant].default};
    font-weight: 700;
    font-size: 1.4rem;
    text-decoration: none;

    cursor: pointer;
    gap: 0.5rem;
  `}
`;

export const Link = styled.a.attrs(({ variant }) => ({ variant: variant || "primary" }))`
  ${({ theme, variant }) => css`
    color: ${theme.colors[variant].default};
    font-weight: 700;
    font-size: 1.4rem;
    text-decoration: none;

    display: flex;
    align-items: center;

    width: 100%;

    gap: 0.5rem;
  `}
`;
